import React, { useRef, useState } from "react"
import emailjs from "@emailjs/browser"

const Careers = () => {
  const form = useRef<HTMLFormElement>(null)

  const [result, showResult] = useState(false)

  const sendEmail = e => {
    e.preventDefault()

    if (!form.current) return

    emailjs
      .sendForm(
        "service_ow0yzdq",
        "template_kok9yri",
        form.current,
        "9sVOYX0vzUl0KnRKl"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
    showResult(true)
  }

  return (
    <div className="lg:w-[60%] w-[100%] flex-col flex">
      <h2 className="text-white text-4xl font-bold tracking-wide lg:mt-44 mt-32 lg:pl-0 pl-3">
        Submit a form
      </h2>
      <p className="text-slate-500 text-lg my-5 lg:pl-0 pl-3">
        PAR 27: Lorem ipsum dolor sit amet, consectetuer adipiscing elit sed
        nisi undeq sim pot.
      </p>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col w-[100%] items-center justify-between "
      >
        <div className="flex lg:flex-row flex-col w-[100%] items-center justify-between">
          <div className="flex flex-col lg:w-[45%] w-[90%] justify-between items-baseline">
            <Input placeholder="Name" name="from_name" />
            <Input placeholder="E-mail" name="Email" />
            <Input placeholder="Lorem ipsum" name="LoremIpsum" />
            <Input placeholder="Lorem ipsum" name="LoremIpsum" />
          </div>
          <div className="flex flex-col lg:w-[45%] w-[90%] justify-between items-baseline">
            <Input placeholder="Lorem ipsum" name="LoremIpsum" />
            <Input placeholder="Lorem ipsum" name="LoremIpsum" />
            <Input placeholder="Lorem ipsum" name="LoremIpsum" />
            <Input placeholder="Lorem ipsum" name="LoremIpsum" />
          </div>
        </div>
        <button
          type="submit"
          className="border-2 py-2 px-20 lg:mt-20 my-10 text-white"
        >
          Send Results
        </button>
      </form>
      <div>
        {result ? (
          <div className="text-slate-300 text-2xl bg-green-300 bg-opacity-50 p-2 px-4 border-2 mt-5 rounded text-center ">
            <p>E-mail sent</p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

const Input = ({ placeholder, name }) => (
  <input
    type="text"
    placeholder={placeholder}
    name={name}
    className="w-[100%] border-b-2 py-2 outline-none bg-transparent my-3 text-white !ring-0 focus:border-white border-0"
  />
)

export default Careers
