import React from "react"
import Careers from "../components/Careers/Careers"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"

const careersPage = () => {
  return (
    <div>
      <Navbar />
      <article className="w-full h-auto flex items-center justify-center">
        <Careers />
      </article>
      <Footer />
    </div>
  )
}

export default careersPage
